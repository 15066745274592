document.addEventListener("DOMContentLoaded", () => {
    let element = document.getElementById("navbar");

    let scroll = Math.max(Math.min(window.scrollY / 150, 0.7), 0.25);
    element.style.backgroundColor = `rgba(0, 0, 0, ${scroll})`;

    document.addEventListener("scroll", () => {
        let scroll = Math.max(Math.min(window.scrollY / 150, 0.7), 0.25);
        element.style.backgroundColor = `rgba(0, 0, 0, ${scroll})`;
    });
});

